import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';

import { useNotAvailableUnknownText } from '../../../../hooks/useNotAvailableUnknownText';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import {
  ProviderDetails,
  ProviderLocation,
  ProviderType,
} from '../../../../models/ProviderDetails';
import {
  getInNetworkStartDate,
  getPracticeLimitations,
  getYesNoNAText,
} from '../../../../utils/providerDetails.utils';
import { capitalizeEachWord } from '../../../../utils/providerNames.utils';
import { phoneOnly } from '../../../ConstantsStyles';
import { CopyToClipBoard } from '../CopyToClipBoard';
import { InfoProviderContent } from '../InfoProviderContent';
import { IconAlignmentContainer } from '../ProviderLocation.style';
import {
  FlexContainer,
  InfoContainer,
  InfoProviderContentContainer,
  ProviderGroupContainer,
  infoContainer,
} from '../ProviderLocationsDetails.style';
import { EmailAndWebsiteInfo } from './EmailAndWebsiteInfo';

type Props = {
  selectedLocation: ProviderLocation;
  providerDetails?: ProviderDetails;
};

export const VirtualProviderGroupAllOtherInfo = ({
  selectedLocation,
  providerDetails,
}: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly) || isWidget;

  const practiceLimitation = getPracticeLimitations(
    selectedLocation?.practiceLimitations?.age,
    selectedLocation?.practiceLimitations?.gender
  );
  const inNetworkStartDate = getInNetworkStartDate(
    selectedLocation?.network?.start
  );

  const {
    providerLocations: notAvailableOrUnknownText,
    virtualGroup: virtualGroupNaUnknownText,
  } = useNotAvailableUnknownText();

  return (
    <InfoProviderContentContainer data-testid="virtual-provider-group-all-other-infos">
      <EmailAndWebsiteInfo
        isFromVirtualProviderGroup
        selectedLocation={selectedLocation}
      />
      <InfoProviderContent
        data-auto-testid="videocam-icon"
        icon="videocam"
        infoSubtext={t('VIRTUAL_GROUP_PROVIDER_ABOUT.VIRTUAL_VISITS_SUBTEXT')}
        infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.VIRTUAL_VISITS_ONLY')}
      />

      <InfoProviderContent
        icon="date_range"
        infoSubtext=""
        infoTitle={t(
          'VIRTUAL_GROUP_PROVIDER_ABOUT.OFFERS_EVENING_WEEKENDS_APPOINTMENT'
        )}
        isFromVirtualProviderGroup
      />
      <InfoProviderContent
        icon="accessibility"
        infoSubtext={selectedLocation.accessibility?.join(', ')}
        infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.ACCESSIBILITY')}
      />
      <InfoProviderContent
        icon="translate"
        infoSubtext={selectedLocation.languagesSpokenByOffice?.join(', ')}
        infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.LANGUAGES_SPOKEN_BY_OFFICE')}
      />
      <InfoProviderContent
        icon="translate"
        infoSubtext={selectedLocation?.languagesSpokenByInterpreters
          ?.sort((a, b) => a.localeCompare(b))
          .join(', ')}
        infoTitle={t(
          'PROVIDER_LOCATIONS_DETAILS.LANGUAGES_SPOKEN_BY_INTERPRETER'
        )}
      />
      <InfoProviderContent
        icon="local_hospital"
        infoSubtext={getYesNoNAText(
          t,
          selectedLocation.hasPCPServiceAvailability
        )}
        infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.PCP_SERVICES_AVAILABLE')}
        variant="outlined"
      />
      {providerDetails?.providerType === ProviderType.PRACTITIONER ? (
        <FlexContainer
          css={{
            marginBottom: '3px',
            '@screen < $sm': { marginBottom: '0' },
          }}
        >
          <IconAlignmentContainer>
            <IconMaterial
              color="$primary1"
              css={{ marginTop: '3px' }}
              icon="group"
              size={18}
            />
            <Text css={infoContainer(phoneScreen)} fontWeight="$semibold">
              {t('VIRTUAL_GROUP_PROVIDER_ABOUT.PROVIDER_GROUP')}
              {selectedLocation?.providerGroupsAffiliations?.length > 0 ? (
                selectedLocation?.providerGroupsAffiliations?.map((group) => (
                  <ProviderGroupContainer
                    key={`virtual-provider-group-${group.value}`}
                  >
                    {capitalizeEachWord(group.value)}
                  </ProviderGroupContainer>
                ))
              ) : (
                <InfoContainer>{virtualGroupNaUnknownText}</InfoContainer>
              )}
            </Text>
          </IconAlignmentContainer>
        </FlexContainer>
      ) : null}

      <InfoProviderContent
        icon="pan_tool"
        infoSubtext={
          practiceLimitation.trim().length > 0
            ? practiceLimitation
            : virtualGroupNaUnknownText
        }
        infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.PRACTICE_LIMITATIONS')}
      />

      <InfoProviderContent
        icon="school"
        infoSubtext={inNetworkStartDate}
        infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.CULTURAL_COMPETENCE')}
        variant="outlined"
      />

      <InfoProviderContent
        icon="verified"
        infoSubtext={notAvailableOrUnknownText}
        infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.LICENSE_NUMBER')}
      />

      <InfoProviderContent
        icon="numbers"
        infoSubtext={t(
          'VIRTUAL_GROUP_PROVIDER_ABOUT.NATIONAL_PROVIDER_SUBTEXT'
        )}
        infoTitle={t(
          'VIRTUAL_GROUP_PROVIDER_ABOUT.NATIONAL_PROVIDER_IDENTIFIER'
        )}
      >
        <Flex css={{ gap: '$xs' }}>
          <InfoContainer>
            {t('VIRTUAL_GROUP_PROVIDER_ABOUT.NATIONAL_PROVIDER_SUBTEXT')}
          </InfoContainer>
          <CopyToClipBoard
            label={t(
              'VIRTUAL_GROUP_PROVIDER_ABOUT.COPY_NATIONAL_PROVIDER_SUBTEXT'
            )}
            text={selectedLocation?.locationId}
          />
        </Flex>
      </InfoProviderContent>

      <InfoProviderContent
        icon="person_pin"
        infoSubtext={selectedLocation.locationId}
        infoTitle={t('VIRTUAL_GROUP_PROVIDER_ABOUT.PROVIDER_ID')}
        variant="outlined"
      >
        <Flex css={{ gap: '$xs' }}>
          <InfoContainer>
            {t('VIRTUAL_GROUP_PROVIDER_ABOUT.PROVIDER_SUBTEXT')}
          </InfoContainer>
          <CopyToClipBoard
            label={t('VIRTUAL_GROUP_PROVIDER_ABOUT.COPY_PROVIDER_SUBTEXT')}
            text={selectedLocation?.locationId}
          />
        </Flex>
      </InfoProviderContent>
    </InfoProviderContentContainer>
  );
};
