/* eslint-disable react/no-unused-prop-types */
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { Accordion } from '@abyss/web/ui/Accordion';
import { Badge } from '@abyss/web/ui/Badge';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { find } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { adobeModalTrackEvent } from '../../../../common/AdobeTagging/adobeModalTrackEvent';
import { LinkContainer } from '../../../../common/LinkContainer/LinkContainer';
import { useLagoon } from '../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import {
  CulturalCompetence,
  Degrees,
  Education,
  Identifiers,
  ProviderType,
} from '../../../../models/ProviderDetails';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../../store/useDetailsStore';
import { DetailsStore } from '../../../../store/useDetailsStore/detailsStore';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import {
  capitalizeEachWord,
  capitalizeFirstLetterWithSpecialChar,
} from '../../../../utils/providerNames.utils';
import { getCurrentMember } from '../../../../utils/user.utils';
import { Constants, ReverseCoverageTypesCodes } from '../../../Constants';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import {
  cardHeaderBrowseStyle,
  h2SmallerFontForResponsive,
  mobileOnly,
  phoneOnly,
  tabletOnly,
} from '../../../ConstantsStyles';
import { getFeatureFlag } from '../../../Utils';
import {
  CollapseCardHeaderStyle,
  HospitalAffiliationsContainerStyle,
  collapseSection,
  collapseSectionNewDesign,
} from '../About.styled';
import { handleHospitalAffiliationOnClick } from './helper';
import { HospitalAffiliationDrawer } from './HospitalAffiliationDrawer';
import { ListCulturalCompetencies } from './ListCulturalCompetencies';
import { ListOtherProviderDetails } from './ListOtherProviderDetails';

function listDegrees(t, school: string, degrees: Degrees[]) {
  const getDegree = (degree) =>
    `${degree?.description ? capitalizeEachWord(degree?.description) : ''} (${
      degree?.degreeCode || ''
    })`;

  return school === t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA') ? (
    degrees?.map((degree) => getDegree(degree))
  ) : (
    <React.Fragment>
      <b> {school ? capitalizeEachWord(school) : ''}</b>
      <Layout.Stack alignItems="left" space={2}>
        {degrees?.map((degree) => getDegree(degree))}
      </Layout.Stack>
    </React.Fragment>
  );
}

export const ListItem = styled('ul', {
  listStyle: 'disc',
  marginLeft: '28px',
  fontSize: '18px',
  color: '#4B4D4F',
  marginTop: '12px',
  marginBottom: '24px',
  fontWeight: '$normal',
  '@screen < $sm': { fontSize: '16px' },
  '& li > span': { display: 'flex', gap: '8px', marginBottom: '10px' },
  '& li::marker': { fontSize: '14px' },
  '& li b': { fontWeight: 'normal' },
});

export const FrequentServiceDrawer = styled(Drawer, {
  '.abyss-modal-close-button': {
    top: '24px',
  },
  '.abyss-modal-content-title': {
    fontWeight: '700',
    lineHeight: '32px',
    marginRight: '8px',
    fontStyle: 'normal',
  },
  '@screen < $sm': {
    '.abyss-modal-section-root': { padding: '0px' },
  },
});

interface referralRequiredSpecialties {
  name: string;
}

type Props = {
  collapseConstantKey: string;
  collapseContent?: string[];
  collapseHeader: string;
  culturalCompetencies?: CulturalCompetence[] | string[] | null;
  education?: Education[] | null;
  hospitalAffiliations?: Identifiers[] | null;
  licenseNumber?: string[];
  npi?: string;
  referralRequiredSpecialties?: referralRequiredSpecialties[] | null;
  showCulturalCompetencies?: Boolean;
  accordionItemValue: string;
  areaOfExpertise?: string[];
  frequentServices?: any[];
  isFrequentServicesProvidedEnabled?: boolean;
  providerName?: string;
  isProviderExperienceSectionEnabled?: boolean;
  providerType?: string;
};

export const CollapseProviderDetailsSection = ({
  collapseConstantKey,
  collapseContent,
  collapseHeader,
  culturalCompetencies,
  education,
  hospitalAffiliations,
  licenseNumber,
  npi,
  referralRequiredSpecialties = [],
  showCulturalCompetencies = true,
  accordionItemValue,
  areaOfExpertise,
  frequentServices,
  isFrequentServicesProvidedEnabled,
  providerName,
  isProviderExperienceSectionEnabled,
  providerType,
}: Props) => {
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly);
  const mobileScreen = useMediaQuery(mobileOnly);
  const tabletScreen = useMediaQuery(tabletOnly) || isWidget;
  const widgetPhoneScreen = useMediaQuery(phoneOnly) && isWidget;
  const WidgetDesktopScreen = !useMediaQuery(phoneOnly) && isWidget;
  const WidgetPages = widgetPhoneScreen || WidgetDesktopScreen;

  const { navigate } = useRouter();
  const currentMember = getCurrentMember();
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const lagoonData = useLagoon('ui-messaging')();
  const referralRequiredEnabled = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.REFERRAL_REQUIRED_BANNER
  );

  const doShowReferralBanner =
    currentMember.isReferralRequired && referralRequiredEnabled;

  const frequentServiceLagoonData = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.FREQUENT_SERVICES_TITLE_DESCRIPTION,
  });
  const frequentServiceDescriptionNoData = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.FREQUENT_SERVICES_DESCRIPTION_NO_DATA,
  });

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isFrequestServiceDrawerOpen, setIsFrequestServiceDrawerOpen] =
    useState(false);

  const chipStore = useChipStore(useShallow((state: ChipState) => state));

  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );

  function listHospitalAffiliationsResponsive(
    name: string,
    providerId: string
  ) {
    return (
      <Layout.Stack alignItems="left" space={2}>
        {isWidget ? (
          <Text
            color="#4B4D4F"
            data-testid="hospital-affiliations-widget"
            fontWeight="$medium"
            size={mobileScreen ? '$md' : '18px'}
          >
            {capitalizeEachWord(name)}
          </Text>
        ) : (
          <Link
            data-auto-testid="hospital-affiliations-content-desktop"
            data-testid="hospital-affiliations-content-desktop"
            fontWeight="$bold"
            isStandardAnchor
            onClick={() => {
              handleHospitalAffiliationOnClick(providerId, name, navigate, {
                chipStore,
                setTypeaheadSearchStore,
                setDetailsStore,
              });
            }}
          >
            {capitalizeEachWord(name)}
          </Link>
        )}
      </Layout.Stack>
    );
  }

  function hospitalAffiliationLink(name, hospitalAffiliationProviderId) {
    return (
      <Link
        css={{
          'abyss-link-root': {
            maxWidth: '155px',
          },
        }}
        data-auto-testid="hospital-affiliations-content-desktop"
        data-testid="hospital-affiliations-content-desktop"
        fontWeight="$bold"
        isStandardAnchor
        onClick={() =>
          handleHospitalAffiliationOnClick(
            hospitalAffiliationProviderId,
            name,
            navigate,
            {
              chipStore,
              setTypeaheadSearchStore,
              setDetailsStore,
            }
          )
        }
      >
        <Text
          color="$info1"
          css={{
            'abyss-text-root': {
              maxWidth: '127px',
              fontWeight: '$semibold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        >
          {capitalizeEachWord(name)}
        </Text>
        <IconMaterial css={{ marginLeft: '4px' }} icon="chevron_right" />
      </Link>
    );
  }

  function hospitalAffiliationsChips(
    name: string,
    hospitalAffiliationProviderId: string,
    tooltip: boolean
  ) {
    return (
      <Flex css={HospitalAffiliationsContainerStyle}>
        {tooltip ? (
          <Tooltip
            align="center"
            content={capitalizeEachWord(name)}
            css={{
              'abyss-text-root': {
                fontSize: '14.22px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '16px',
              },
            }}
            position="top"
            positionOffset={8}
          >
            {hospitalAffiliationLink(name, hospitalAffiliationProviderId)}
          </Tooltip>
        ) : (
          hospitalAffiliationLink(name, hospitalAffiliationProviderId)
        )}
      </Flex>
    );
  }

  const ProviderExperienceSectionDetails = ({
    heading,
    content,
    description = null,
    type = null,
  }: any) => (
    <React.Fragment>
      <Text
        color="#4B4D4F"
        css={{ '@screen < $sm': { fontSize: '16px' } }}
        data-testid={`${heading.replace(/ /g, '-').toLowerCase()}-heading`}
        fontWeight={700}
        size={20.25}
      >
        {heading}
      </Text>
      {description && (
        <Flex css={{ margin: '12px 0px' }}>
          <Text>{description}</Text>
        </Flex>
      )}
      <ListItem>
        {content?.map((item, index) => {
          const referralRequired =
            doShowReferralBanner && type === 'specialties'
              ? referralRequiredSpecialties?.find(
                  (rrs) => rrs?.name?.toLowerCase() === item?.toLowerCase()
                )
              : false;
          return (
            <li key={`provider-experience-${item}-${index}`}>
              <span>
                <Text
                  css={{ fontSize: (mobileScreen && '16px') || '18px' }}
                  data-testid={
                    type === 'specialties'
                      ? 'provider-with-sub-specialities'
                      : `provider-with-sub-${type}`
                  }
                >
                  {item}
                </Text>
                {referralRequired && (
                  <Text>
                    {mobileScreen ? (
                      <Badge
                        data-testid={`${item}-ref-req-mobile`}
                        variant="info"
                      >
                        {t('Referral required')}
                      </Badge>
                    ) : (
                      <Badge
                        data-testid="referral-required"
                        outline
                        variant="info"
                      >
                        {t('Referral required')}
                      </Badge>
                    )}
                  </Text>
                )}
              </span>
            </li>
          );
        })}
      </ListItem>
    </React.Fragment>
  );

  const ProviderExperienceSections = () => (
    <Layout.Group
      alignItems="top"
      alignLayout="between"
      css={{
        gap: 24,
        marginTop: mobileScreen ? 0 : 16,
        '& > div': { flex: 1 },
        '@screen < $sm': { display: 'block !important' },
      }}
    >
      <div>
        {collapseContent?.length && (
          <ProviderExperienceSectionDetails
            content={collapseContent}
            heading={t(
              'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.SPECIALTIES'
            )}
            type="specialties"
          />
        )}
        {areaOfExpertise?.length && (
          <ProviderExperienceSectionDetails
            content={areaOfExpertise}
            heading={t(
              'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.AREAS_OF_EXPERTISE'
            )}
            type="areas-of-expertise"
          />
        )}
      </div>
      <div>
        <span data-testid="education-label">
          <Text
            color="#4B4D4F"
            css={{ '@screen < $sm': { fontSize: '16px' } }}
            data-testid={'provider-with-sub-specialities-education'}
            fontWeight={700}
            size={20.25}
          >
            {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.EDUCATION')}
          </Text>
          <ListItem color="#4B4D4F">
            {education?.map((item, index) => (
              <li key={`provider-education-${item?.schoolName}-${index}`}>
                {listDegrees(t, item.schoolName, item.degrees)}
              </li>
            ))}
          </ListItem>
          {showCulturalCompetencies && (
            <Text
              color="#4B4D4F"
              css={{ '@screen < $sm': { fontSize: '16px' } }}
              data-testid="provider-exprience-section-cultural-competence"
              fontWeight={700}
              key="expand-section-cultural-competence"
              size={20.25}
            >
              <Layout.Stack alignItems="left" space={2}>
                <ListCulturalCompetencies
                  culturalCompetencies={culturalCompetencies}
                  isFrequentServicesProvidedEnabled={
                    isFrequentServicesProvidedEnabled
                  }
                  isProviderExperienceSectionEnabled={
                    isProviderExperienceSectionEnabled
                  }
                  mobileScreen={mobileScreen}
                />
              </Layout.Stack>
            </Text>
          )}
        </span>
      </div>
      {isFrequentServicesProvidedEnabled &&
        chipStore?.coverageType === 'M' &&
        providerType === ProviderType.PRACTITIONER &&
        (frequentServices?.length ? (
          <div>
            <ProviderExperienceSectionDetails
              content={frequentServices
                ?.slice(0, 10)
                ?.map((item) => item?.title)}
              description={frequentServiceLagoonData?.message?.replace(
                '{{name}}',
                providerName
              )}
              heading={frequentServiceLagoonData?.title}
              type="frequent-services"
            />
            <Flex
              alignItems="center"
              css={{ marginTop: '16px' }}
              data-auto-testid="view-definition-frequent-services"
              data-testid="view-definition-frequent-services"
              onClick={() => setIsFrequestServiceDrawerOpen(true)}
            >
              <Text
                color="$interactive1"
                css={{
                  display: 'flex',
                  alignItems: phoneScreen ? 'flex-start' : 'center',
                  cursor: 'pointer',
                }}
                fontWeight="700"
                size={phoneScreen ? 14 : 16}
              >
                <IconMaterial
                  css={{ marginRight: '2px' }}
                  icon="info"
                  size={phoneScreen ? 14 : 16}
                  variant="outlined"
                />
                {t(
                  'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.VIEW_DEFINITION_FREQUENT_SERVICES'
                )}
              </Text>
            </Flex>
          </div>
        ) : (
          <div>
            <ProviderExperienceSectionDetails
              content={[
                `${frequentServiceDescriptionNoData?.message?.replace(
                  '{{name}}',
                  providerName
                )}.`,
              ]}
              heading={frequentServiceLagoonData?.title}
            />
          </div>
        ))}
    </Layout.Group>
  );

  const getAboutDetailResponsive = (detailCollapseHeaderResponsive) => {
    switch (detailCollapseHeaderResponsive) {
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.EDUCATION:
        return (
          <React.Fragment>
            {education?.map((item) => (
              <Layout.Stack
                alignItems="left"
                css={{ color: '#4B4D4F', fontSize: '16px' }}
                key={`collapse-section-${item?.schoolName}`}
                space={2}
              >
                {listDegrees(t, item.schoolName, item.degrees)}
              </Layout.Stack>
            ))}
            {showCulturalCompetencies && (
              <Layout.Stack
                alignItems="left"
                css={{ color: '#4B4D4F', fontSize: '16px', marginTop: '12px' }}
                key="collapse-section-cultural-competence"
                space={2}
              >
                <ListCulturalCompetencies
                  culturalCompetencies={culturalCompetencies}
                  mobileScreen={mobileScreen}
                />
              </Layout.Stack>
            )}
          </React.Fragment>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .HOSPITAL_AFFILIATIONS:
        return hospitalAffiliations?.slice(0, 4)?.map((item) =>
          item.value === t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA') ? (
            <Text
              color="#4B4D4F"
              fontWeight="$medium"
              key={`hospital-affiliations-na`}
              size={mobileScreen ? '$md' : '18px'}
            >
              {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA')}
            </Text>
          ) : (
            listHospitalAffiliationsResponsive(item.value, item.id)
          )
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .OTHER_PROVIDER_DETAILS:
        return (
          <Layout.Stack alignItems="left" space={12}>
            <ListOtherProviderDetails
              idName={t(
                'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NATIONAL_PROVIDER_IDENTIFIER'
              )}
              idNumber={npi}
            />
            <ListOtherProviderDetails
              idName={t(
                'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.LICENSE_NUMBER'
              )}
              idNumber={licenseNumber?.join(', ')}
            />
          </Layout.Stack>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .PROVIDER_SPECIALTIES:
        return collapseContent?.map((item) => {
          const referralRequired =
            doShowReferralBanner &&
            referralRequiredSpecialties?.find(
              (rrs) => rrs?.name?.toLowerCase() === item?.toLowerCase()
            );
          return (
            <Layout.Stack key={`collapse-section-${item}`}>
              {capitalizeEachWord(item)}
              {referralRequired ? (
                <Badge data-testid={`${item}-ref-req-mobile`} variant="info">
                  {t('Referral required')}
                </Badge>
              ) : null}
            </Layout.Stack>
          );
        });
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .PROVIDER_EXPERIENCE:
        return <ProviderExperienceSections />;
      default:
        return collapseContent?.map((item) => (
          <Layout.Stack
            css={{ color: '#4B4D4F', fontSize: '16px' }}
            key={`collapse-section-${item}`}
          >
            {capitalizeFirstLetterWithSpecialChar(item)}
          </Layout.Stack>
        ));
    }
  };

  const getAboutDetail = (detailCollapseHeader) => {
    switch (detailCollapseHeader) {
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.EDUCATION:
        return (
          <span data-testid="education-label">
            {education?.map((item) => (
              <Text
                color="#4B4D4F"
                fontWeight="$medium"
                key={`expand-section-${item?.schoolName}`}
                size={18}
              >
                <Layout.Stack alignItems="left" space={2}>
                  {listDegrees(t, item.schoolName, item.degrees)}
                </Layout.Stack>
              </Text>
            ))}
            {showCulturalCompetencies && (
              <Text
                color="#4B4D4F"
                fontWeight="$medium"
                key="expand-section-cultural-competence"
                size={18}
              >
                <Layout.Stack
                  alignItems="left"
                  space={2}
                  style={{ marginTop: '12px' }}
                >
                  <ListCulturalCompetencies
                    culturalCompetencies={culturalCompetencies}
                    mobileScreen={mobileScreen}
                  />
                </Layout.Stack>
              </Text>
            )}
          </span>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .HOSPITAL_AFFILIATIONS:
        return (
          <Layout.Group>
            {hospitalAffiliations?.slice(0, 6).map((item) =>
              item.value ===
              t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA') ? (
                <Text
                  color="#4B4D4F"
                  fontWeight="$medium"
                  key={`hospital-affiliations-na`}
                  size={mobileScreen ? '$md' : '18px'}
                >
                  {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA')}
                </Text>
              ) : (
                hospitalAffiliationsChips(item.value, item.id, !tabletScreen)
              )
            )}
          </Layout.Group>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .OTHER_PROVIDER_DETAILS:
        return (
          <Layout.Stack alignItems="left" space={24}>
            <ListOtherProviderDetails
              idName={t(
                'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NATIONAL_PROVIDER_IDENTIFIER'
              )}
              idNumber={npi}
            />
            <ListOtherProviderDetails
              idName={t(
                'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.LICENSE_NUMBER'
              )}
              idNumber={licenseNumber?.join(', ')}
            />
          </Layout.Stack>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .PROVIDER_SPECIALTIES:
        return collapseContent?.map((item) => {
          const referralRequired =
            doShowReferralBanner &&
            referralRequiredSpecialties?.find(
              (rrs) => rrs?.name?.toLowerCase() === item?.toLowerCase()
            );
          return (
            <Layout.Group
              data-testid="provider-with-specialities"
              key={`expand-section-${item}`}
            >
              <Text
                color="#4B4D4F"
                data-testid="provider-with-sub-specialities"
                fontWeight="$medium"
                size={18}
              >
                {capitalizeEachWord(item)}
              </Text>
              {referralRequired ? (
                <Badge data-testid="referral-required" outline variant="info">
                  {t('Referral required')}
                </Badge>
              ) : null}
            </Layout.Group>
          );
        });
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .PROVIDER_EXPERIENCE:
        return <ProviderExperienceSections />;
      default:
        return collapseContent?.map((item) => (
          <Text
            color="#4B4D4F"
            data-testid="provider-aoe-speciality"
            fontWeight="$medium"
            key={`expand-section-${item}`}
            size={18}
          >
            {capitalizeFirstLetterWithSpecialChar(item)}
          </Text>
        ));
    }
  };

  const hospitalAffiliationsViewAll = () => {
    if (
      t(collapseHeader) ===
        t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.HOSPITAL_AFFILIATIONS') &&
      (chipStore.coverageType === ReverseCoverageTypesCodes.MEDICAL ||
        ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']) &&
      (mobileScreen
        ? (hospitalAffiliations?.length || 0) > 4
        : (hospitalAffiliations?.length || 0) > 6)
    ) {
      return (
        <Flex>
          <LinkContainer
            analyticsInfo={{
              location: 'body:hospital affiliation',
            }}
            aria-label={`${t('View all')} ${t(collapseHeader)}`
              .replace(/ /g, '-')
              .toLocaleLowerCase()}
            css={{ marginTop: mobileScreen ? '8px' : '' }}
            data-auto-testid="hospital-affiliation-section-container-view-all"
            data-testid="hospital-affiliation-section-container-view-all"
            iconSize="$sm"
            onClick={() => {
              adobeModalTrackEvent(
                Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
                  .HOSPITAL_AFFILIATIONS_DRAWER
              );
              setIsOpen(true);
            }}
            size={mobileScreen ? '12.64px' : '16px'}
            text={t('View all')}
          />
          <HospitalAffiliationDrawer
            hospitalAffiliations={hospitalAffiliations}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </Flex>
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      {phoneScreen || isWidget ? (
        <Layout.Group
          css={collapseSection(
            true,
            mobileScreen,
            phoneScreen,
            widgetPhoneScreen,
            WidgetDesktopScreen,
            WidgetPages
          )}
        >
          <Accordion css={CollapseCardHeaderStyle(isWidget)} isCollapsible>
            <Accordion.Item value={accordionItemValue}>
              <Accordion.Trigger>
                <Accordion.Header>{t(collapseHeader)}</Accordion.Header>
              </Accordion.Trigger>
              <Accordion.Content>
                {getAboutDetailResponsive(collapseConstantKey)}
                {hospitalAffiliationsViewAll()}
              </Accordion.Content>
            </Accordion.Item>
          </Accordion>
        </Layout.Group>
      ) : (
        <Layout.Group
          css={
            (isProviderExperienceSectionEnabled && collapseSectionNewDesign) ||
            collapseSection(false, mobileScreen, phoneScreen)
          }
          isFullWidth={
            collapseHeader ===
            t(
              'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.HOSPITAL_AFFILIATIONS'
            )
          }
        >
          <Layout.Stack alignItems="left">
            <Flex css={cardHeaderBrowseStyle} justify="start">
              <Heading
                css={h2SmallerFontForResponsive}
                data-testid={`${t(collapseHeader)}`
                  .replace(/ /g, '-')
                  .toLocaleLowerCase()}
                display="h4"
                offset={1}
              >
                {t(collapseHeader)}
              </Heading>
              {hospitalAffiliationsViewAll()}
            </Flex>
            {getAboutDetail(collapseConstantKey)}
          </Layout.Stack>
        </Layout.Group>
      )}

      {isFrequestServiceDrawerOpen && frequentServices?.length && (
        <FrequentServiceDrawer
          isOpen={isFrequestServiceDrawerOpen}
          onClose={() => setIsFrequestServiceDrawerOpen(false)}
          position={mobileScreen ? 'bottom' : 'right'}
          size={mobileScreen ? window.innerHeight - 24 : 408}
          title={
            <div
              data-auto-testid="frequent-services-title"
              data-testid="frequent-services-title"
            >
              {frequentServiceLagoonData?.title}
            </div>
          }
        >
          <Drawer.Section>
            <Accordion
              css={CollapseCardHeaderStyle(isWidget)}
              defaultValue={`${frequentServices[0]?.title}-0`}
              isCollapsible
            >
              {frequentServices?.slice(0, 10)?.map((service, index) => (
                <Accordion.Item
                  css={{ borderBottom: '1px solid $gray5' }}
                  value={`${service?.title}-${index}`}
                >
                  <Accordion.Trigger
                    css={{
                      marginBottom: '8px',
                      padding: '12px 16px !important',
                    }}
                  >
                    <Accordion.Header>{t(service?.title)}</Accordion.Header>
                  </Accordion.Trigger>
                  <Accordion.Content
                    css={{ padding: '6px 16px 16px 16px !important' }}
                  >
                    {t(service?.description)}
                  </Accordion.Content>
                </Accordion.Item>
              ))}
            </Accordion>
          </Drawer.Section>
        </FrequentServiceDrawer>
      )}
    </React.Fragment>
  );
};
