import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNotAvailableUnknownText } from '../../../hooks/useNotAvailableUnknownText';
import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import { FacilityLocationsResponse } from '../../../models/FacilityDetails';
import { ProviderLocation } from '../../../models/ProviderDetails';
import {
  convertTimeToAmPm,
  filterNotEmptyHoursOfOperations,
  getUncommonElements,
  groupingClosingAndOpeningTime,
} from '../../../utils/providerDetails.utils';
import { Constants } from '../../Constants';
import { phoneOnly } from '../../ConstantsStyles';
import { IconAlignmentContainer } from './ProviderLocation.style';
import {
  FlexContainer,
  IconMaterialContainer,
  infoContainer,
  timeContainer,
} from './ProviderLocationsDetails.style';

type Props = {
  selectedLocation: ProviderLocation | FacilityLocationsResponse;
};

export const OpsHours = ({ selectedLocation }: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly) || isWidget;

  // Parse payload
  const hoursOfOperation = selectedLocation?.hoursOfOperation || {};

  // utils calls
  const notEmptyHoursOfOperation =
    filterNotEmptyHoursOfOperations(selectedLocation);

  const notAvailableDays = getUncommonElements(
    Constants.ALL_DAYS,
    notEmptyHoursOfOperation.map((days) => days.day)
  );

  const groupByClosingAndOpeningTime = groupingClosingAndOpeningTime(
    t,
    notEmptyHoursOfOperation
  );

  const translationOfNotAvailableDays = notAvailableDays.map((day) => t(day));

  const { providerLocations: notAvailableOrUnknownText } =
    useNotAvailableUnknownText();

  return (
    <React.Fragment>
      <FlexContainer
        data-auto-testid="provider-availability-details"
        data-testid="provider-availability-details"
      >
        {hoursOfOperation?.length ? (
          <IconAlignmentContainer css={{ alignItems: 'flex-start' }}>
            <IconMaterialContainer
              color="$primary1"
              icon="access_time"
              isScreenReadable
              size={18}
              title="Hours"
            />
            <div>
              {Object.keys(groupByClosingAndOpeningTime).map((open) => (
                <div key={`${open}`}>
                  {Object.keys(groupByClosingAndOpeningTime[open]).map(
                    (close) => (
                      <Flex alignItems="center" key={`${open}-${close}`}>
                        <Text
                          css={infoContainer(phoneScreen)}
                          fontWeight="$semibold"
                        >
                          {groupByClosingAndOpeningTime[open][close].map(
                            (days, index) =>
                              (index ? ', ' : '') + days.slice(0, 3)
                          )}
                          :
                        </Text>
                        <Text css={timeContainer(phoneScreen)}>
                          {' '}
                          {convertTimeToAmPm(open.slice(0, 5))} -{' '}
                          {convertTimeToAmPm(close.slice(0, 5))}
                        </Text>
                      </Flex>
                    )
                  )}
                </div>
              ))}
              {translationOfNotAvailableDays?.length > 0 && (
                <Flex alignItems="center">
                  <Text css={infoContainer(phoneScreen)} fontWeight="$semibold">
                    {translationOfNotAvailableDays?.map(
                      (DaysNotAvailable, index) =>
                        (index ? ', ' : '') + t(DaysNotAvailable.slice(0, 3))
                    )}
                    :
                  </Text>
                  <Text css={timeContainer(phoneScreen)}>
                    {notAvailableOrUnknownText}
                  </Text>
                </Flex>
              )}
            </div>
          </IconAlignmentContainer>
        ) : (
          <IconAlignmentContainer>
            <IconMaterialContainer
              color="$primary1"
              icon="access_time"
              isScreenReadable
              size={16.67}
              title="Hours"
            />
            <Text css={infoContainer(phoneScreen)} fontWeight="$semibold">
              {notAvailableOrUnknownText}
            </Text>
          </IconAlignmentContainer>
        )}
      </FlexContainer>
    </React.Fragment>
  );
};
